/* the norm */
#gritter-notice-wrapper {
	position:fixed;
	top:20px;
	right:20px;
	width:27%;
	z-index:9999;
	
}

.success-class{
	background-color: #338ed8;
}
.gritter-error{	
	background-color: #ee255d!Important;
}
#gritter-notice-wrapper.top-left {
    left: 20px;
    right: auto;
}
#gritter-notice-wrapper.bottom-right {
    top: auto;
    left: auto;
    bottom: 20px;
    right: 20px;
}
#gritter-notice-wrapper.bottom-left {
    top: auto;
    right: auto;
    bottom: 20px;
    left: 20px;
}
.gritter-item-wrapper {
	position:relative;
	margin:0 0 10px 0;
}
.gritter-top {
	height:10px;
}
.hover .gritter-top {
	background-position:right -30px;
}
.gritter-bottom {
	height:8px;
	margin:0;
}
.hover .gritter-bottom {
	background-position: bottom right;
}
.gritter-item {
	display:block;
	color:#eee;
	padding:2px 11px 8px 11px;
	font-size: 11px;
	font-family:verdana;
}
.hover .gritter-item {
	background-position:right -40px;
}
.gritter-item p {
	font-size: 14px;
	padding:0;
	margin:0;
	word-wrap:break-word;
}

.gritter-title {
	font-size:20px;
	font-weight:bold;
	padding:0 0 7px 0;
	display:block;
	text-shadow:1px 1px 0 #000; /* Not supported by IE :( */
}
.gritter-image {
	width:25%;
	height:auto;
	float:left;
}
.gritter-with-image,
.gritter-without-image {
	padding:0;
}
.gritter-with-image {
	width:70%;
	float:right;
}
/* for the light (white) version of the gritter notice */
.gritter-light .gritter-item,
.gritter-light .gritter-bottom,
.gritter-light .gritter-top,
.gritter-light {
    color: #222;
}
.gritter-light .gritter-title {
    text-shadow: none;
}
.close-button {
    background-image: url("data:image/svg+xml,%3Csvg width=\'18\' height=\'16\' viewBox=\'715 452 18 16\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M724 456.632L719.368 452 716 455.368l4.632 4.632-4.632 4.632 3.368 3.368 4.632-4.632 4.632 4.632 3.368-3.368-4.632-4.632 4.632-4.632-3.368-3.368-4.632 4.632z\' fill=\'%23FFF\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")!important;
}