/* ------ CDC style---------- */
div[id^="elanco-embed"] .gigya-screen a, div[id^="elanco-embed"] .gigya-screen a:active, div[id^="elanco-embed"] .gigya-screen a:link, div[id^="elanco-embed"] .gigya-screen a:visited {		
    color: #000;
}
#elanco-embed .gigya-composite-control.gigya-composite-control-submit{
    width: 70%;
    margin:0 auto;
}	
div[id^="elanco-embed"] .gigya-screen .gigya-layout-cell{	
    text-align: left;
}
div[id^="elanco-embed"] {			
    width: 100% !important;
}
div[id^="elanco-embed"] .gigya-screen.portrait.mobile:before{ 
    width: 100% !important;
}
div[id^="elanco-embed"] .gigya-layout-cell .gigya-composite-control.gigya-composite-control-link{
    margin-top:15px;
    text-decoration: underline;
}
div[id^="elanco-embed"] .gigya-screen.portrait{
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    max-width: 500px;
    width: 100%
}
  div[id^="elanco-embed"] .gigya-screen .gigya-composite-control.gigya-composite-control-textbox input, div[id^="elanco-embed"] .gigya-screen .gigya-composite-control.gigya-composite-control-password input, div[id^="elanco-embed"] .gigya-screen .gigya-composite-control.gigya-composite-control-dropdown select{
    min-height: 51px;
    margin-bottom: 0 !important;
    border-color: #ccd9e1 !important;
    border-width: 1px 2px 2px 1px !important;
    border-style: solid;
    outline: none;
}
div[id^="elanco-embed"] .gigya-screen .gigya-composite-control.gigya-composite-control-textbox .gigya-label,  div[id^="elanco-embed"] .gigya-screen .gigya-composite-control.gigya-composite-control-password,  div[id^="elanco-embed"] .gigya-screen .gigya-composite-control.gigya-composite-control-dropdown {
    margin-top: 25px!important;
    margin-bottom: 10px !important;
    padding-bottom: 0 !important;
    outline: none;
} 
#elanco-embed-register .gigya-screen input[type=submit]{
    width: auto;
    padding: 5px 10px;
}
/* Login, change password, Registration and edit profile style */
#elanco-embed .gigya-screen.portrait{
    padding: 0;
    max-width: 365px;
    width:100%;
    margin: 0 auto;
}
#signIn {
    margin: 0 auto;
    width: 100% !important
}
#elanco-embed .gigya-screen-content, #elanco-embed-change .gigya-screen-content {
    background-color: #ffffff !important;
}
#elanco-embed h1.gigya-screen-caption{
    font-size: 1.5em;
    color: #000;
    font-weight: 200;
    border-bottom: none;
    max-width: 365px; 
    width:100%;
    padding-left: 0;
    margin: 0 auto;
}
#elanco-embed-change h1.gigya-screen-caption {
    margin-left: 0;
    border-bottom: 2px solid #dbdbdb;
    border-style: solid;
    width: 100%;
    font-size: 1.5em;
    color: #004267;
    font-weight: 200;
    padding-left: 0;
    text-transform: uppercase;
}
#elanco-embed-change, .gigya-screen input[type=submit], #elanco-embed-profile .gigya-screen input[type=submit]{
    width: auto;
    padding: 5px 10px;
}
#elanco-embed-change .gigya-screen .gigya-composite-control.gigya-composite-control-submit{
   text-align: center;
}
#elanco-embed-change .gigya-screen .gigya-composite-control.gigya-composite-control-textbox .gigya-label, #elanco-embed-change .gigya-screen .gigya-composite-control.gigya-composite-control-password .gigya-label{
    text-transform: uppercase;
}
#elanco-embed .gigya-screen .gigya-composite-control.gigya-composite-control-textbox, #elanco-embed .gigya-screen .gigya-composite-control.gigya-composite-control-password{
    margin-top: 25px!important;
    margin-bottom: 25px !important;
    padding-bottom: 0 !important;
    outline: none;
}
#elanco-embed .gigya-screen .gigya-layout-cell {
    text-align: center;
}
@media only screen and (min-width: 641px) {
    .modalPattern#login-modalPattern-change {
        max-width: 500px;
    }
}
#elanco-embed-register h1.gigya-screen-caption, #elanco-embed-profile h1.gigya-screen-caption{
    display: none;
  }
  #elanco-embed-register .gigya-screen.portrait, #elanco-embed-profile .gigya-screen.portrait {
    font-size: 16px;
    padding-top: 30px;
}
.gigya-screen-loader {
    display: none !important;
}
#elanco-embed-reset .gigya-screen.portrait {
    margin: 0 auto;
}
.gigya-input-submit{
    box-sizing: border-box;
}
.reg-style-ch{
    padding: 0 !important;
    background-color: white !important;
    color: #000 !important;
    border: none !important;
    margin-top: 1.5em !important;
    text-decoration: underline !important;
    display: inline-block;
}