/* Footer siempre abajo: FIXED */
/*html, body  {
    height: 100% !important;
}

.inner-body-wrapper {
 min-height: 100% !important;
 padding-bottom: 190px !important;
}*/

/*footer {
    position: absolute !important;
    bottom: 0 !important;
    width: 100% !important;
}
*/
/* Footer */

.imgLogo {
 width: 86px;
 height: 43px;
}

.masonry-brick {
    width: 32%;
    margin-left: 1.3%;
    margin-bottom: 2%;
    margin-top: 0px !important;
}
#loggedPanel .masonry-brick{
    width: 49%;
    margin-left: 0%;
    margin-bottom: 2%;
}
#loggedPanel .masonry-brick:first{

    margin-left: 0%;
}

.logged .businessBox{
    margin-top: 11px;
}



.sharedSocial  {
    color:#004267;
   /*font-size: 2em;*/
}
.btnDownload{
    font-size: .73em;
}

.markedNewLine{
    width: 100%;
    white-space:pre-wrap;
}

.markedNewLine > ul{
    list-style-type: square;
    margin-left: 70px;
}

.markedNewLine > ol{
    list-style-type: decimal;
    margin-left: 45px;
}

.primary-navigation-inner > .meta > .account > .account-symbol
{
    padding-right: 25px !important;
}
/*.carousel-inner .active{
   -webkit-animation: fadeinout 3s linear forwards;
    animation: fadeinout 3s linear forwards;
    opacity: 0;
}
@-webkit-keyframes fadeinout {
  50% { opacity: 1; }
}

@keyframes fadeinout {
  50% { opacity: 1; }
}*/
/**************************************************************************/

.color-light-blue{
	color: #338ed8;
}

.modal-open {
  overflow: hidden;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal button{
	cursor: pointer;
}
.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform .3s ease-out;
       -o-transition:      -o-transform .3s ease-out;
          transition:         transform .3s ease-out;
  -webkit-transform: translate(0, -25%);
      -ms-transform: translate(0, -25%);
       -o-transform: translate(0, -25%);
          transform: translate(0, -25%);
}
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
       -o-transform: translate(0, 0);
          transform: translate(0, 0);
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
          box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}
.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: .5;
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.modal-header .close {
  margin-top: -2px;
}
.modal-title {
  margin: 0;
  line-height: 1.42857143;
}
.modal-body {
  position: relative;
  padding: 15px;
}
.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
            box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  }
  .modal-sm {
    width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.app-modal-window-promotions .modal-dialog {
  width: 1250px;
}
.app-modal-window-status .modal-dialog{
	width: 600px
}

/*************************************************************/
.red{
	color: #ee255d;
}

.pull-right {
  float: right !important;
}
.text-center {
  text-align: center;
}

.list-group{
	margin-top:15px;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #F2F5FC;
  border: 1px solid #ccd9e1;
}
.list-group-item:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.list-group-item:last-child {
  margin-bottom: 0;
}

.checkbox-group>.tier {
    width: 12.33%!important;
}

.fa-times{
	cursor: pointer;
}

.logged> .panel-btn{
	margin-top: 20px;
}
.logged> .panel-btn:first-child{
	margin-top: 0px;
}


.field.text textarea {
    font-weight: 200;
    color: #004267;
    font-family: "adelle", serif;
    padding: 14px 15px 14px;
    background-color: #FFF;

    margin: 0;
    width: 100%;
    font-size: 1.0625em;
}
.active tr{
	background-color: red;
}

.table-btn{
	margin: 0px 5px;
}

.pill-item {
	overflow: hidden;
	white-space: nowrap;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	position: relative;
	color: #536171;
	padding: 1px 2ex 0 2ex;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	line-height: 2rem;
	background: #eef2f4;
}
.pill-list {
    display: flex;
    flex-wrap: wrap;
}
.pill-list > * {
    margin-bottom: 0.642857em;
    margin-right: 1rem;
}

.pill-item.x--draggable {
	padding-left: 3ex;
}
i:hover{
	cursor: pointer;

}

.tableCheck{
	padding-top: 28px!important;
}

.status-indicator.blue-indicator::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width=\'20\' height=\'21\' viewBox=\'0 13 20 21\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cellipse fill=\'%23338ed8\' fill-rule=\'evenodd\' cx=\'10\' cy=\'23.5\' rx=\'10\' ry=\'10\'/%3E%3C/svg%3E");
}
.branding .png-logo {
    width: auto;
    height: 50px;
    margin: 17px 0;
}

.legal{
  border:solid #000;
  padding:3px;
}

td{
  padding-left:1em;
}
/*********************************Corrousel *********************************/
.carousel-inner .item{display: none}
.carousel-inner .active {display: block!Important}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #338ed8;
  text-transform: none;
}
h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}

.cursor-hand{
	cursor: pointer;
	cursor: hand;
}

.requestFormSmallText{
	font-size: x-small;
}

/*********************** Extra Fonts *************************/
@font-face {
	font-family: tk-adelle-n7;
	src:
		url(https://use.typekit.net/af/027749/000000000000000000012b41/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7)
		format("woff2"),
		url(https://use.typekit.net/af/09a90c/000000000000000000012b41/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
		format("woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: tk-adelle-i7;
	src:
		url(https://use.typekit.net/af/de9063/000000000000000000012b42/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7)
		format("woff2"),
		url(https://use.typekit.net/af/fa1bf3/000000000000000000012b42/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
		format("woff");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: tk-adelle-n4;
	src:
		url(https://use.typekit.net/af/f5b9fe/000000000000000000012b3d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4)
		format("woff2"),
		url(https://use.typekit.net/af/27c155/000000000000000000012b3d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
		format("woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: tk-adelle-i4;
	src:
		url(https://use.typekit.net/af/7bf543/000000000000000000012b3e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4)
		format("woff2"),
		url(https://use.typekit.net/af/e98bd4/000000000000000000012b3e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
		format("woff");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: tk-adelle-n3;
	src:
		url(https://use.typekit.net/af/9f15fd/000000000000000000012b3b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3)
		format("woff2"),
		url(https://use.typekit.net/af/0aab56/000000000000000000012b3b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
		format("woff");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: tk-adelle-n1;
	src:
		url(https://use.typekit.net/af/527324/000000000000000000012b2b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1)
		format("woff2"),
		url(https://use.typekit.net/af/85f81b/000000000000000000012b2b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3)
		format("woff");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: tk-adelle-i1;
	src:
		url(https://use.typekit.net/af/98de5c/000000000000000000012b2c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1)
		format("woff2"),
		url(https://use.typekit.net/af/35cd7e/000000000000000000012b2c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3)
		format("woff");
	font-weight: 100;
	font-style: italic;
}

/*@font-face {
	font-family: tk-fira-sans-n4;
	src:
		url(https://use.typekit.net/af/752e96/000000000000000000015891/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4)
		format("woff2"),
		url(https://use.typekit.net/af/193346/000000000000000000015891/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
		format("woff");
	font-weight: 400;
	font-style: normal;
}*/

/*@font-face {
	font-family: tk-fira-sans-i4;
	src:
		url(https://use.typekit.net/af/072be5/000000000000000000015892/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4)
		format("woff2"),
		url(https://use.typekit.net/af/7b374f/000000000000000000015892/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
		format("woff");
	font-weight: 400;
	font-style: italic;
}*/

/*@font-face {
	font-family: tk-fira-sans-n3;
	src:
		url(https://use.typekit.net/af/f4d040/00000000000000000001588f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3)
		format("woff2"),
		url(https://use.typekit.net/af/066512/00000000000000000001588f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
		format("woff");
	font-weight: 300;
	font-style: normal;
}*/

/*@font-face {
	font-family: tk-fira-sans-n7;
	src:
		url(https://use.typekit.net/af/cc761e/000000000000000000015895/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7)
		format("woff2"),
		url(https://use.typekit.net/af/e6a673/000000000000000000015895/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
		format("woff");
	font-weight: 700;
	font-style: normal;
}*/

/*@font-face {
	font-family: tk-fira-sans-i7;
	src:
		url(https://use.typekit.net/af/a37836/000000000000000000015896/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7)
		format("woff2"),
		url(https://use.typekit.net/af/79310d/000000000000000000015896/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
		format("woff");
	font-weight: 700;
	font-style: italic;
}*/

/*@font-face {
	font-family: tk-fira-sans-n2;
	src:
		url(https://use.typekit.net/af/c072e8/000000000000000000015879/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2)
		format("woff2"),
		url(https://use.typekit.net/af/09bef6/000000000000000000015879/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3)
		format("woff");
	font-weight: 200;
	font-style: normal;
}*/

/*@font-face {
	font-family: tk-fira-sans-i2;
	src:
		url(https://use.typekit.net/af/044d56/00000000000000000001587a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2)
		format("woff2"),
		url(https://use.typekit.net/af/5f3fc1/00000000000000000001587a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3)
		format("woff");
	font-weight: 200;
	font-style: italic;
}*/

/*@font-face {
	font-family: tk-fira-sans-n6;
	src:
		url(https://use.typekit.net/af/dac866/000000000000000000015880/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6)
		format("woff2"),
		url(https://use.typekit.net/af/c93c0e/000000000000000000015880/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3)
		format("woff");
	font-weight: 600;
	font-style: normal;
}*/
@font-face {
	font-family: adelle;
	src:
		url(https://use.typekit.net/af/027749/000000000000000000012b41/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7)
		format("woff2"),
		url(https://use.typekit.net/af/09a90c/000000000000000000012b41/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
		format("woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: adelle;
	src:
		url(https://use.typekit.net/af/de9063/000000000000000000012b42/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7)
		format("woff2"),
		url(https://use.typekit.net/af/fa1bf3/000000000000000000012b42/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
		format("woff");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: adelle;
	src:
		url(https://use.typekit.net/af/f5b9fe/000000000000000000012b3d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4)
		format("woff2"),
		url(https://use.typekit.net/af/27c155/000000000000000000012b3d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
		format("woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: adelle;
	src:
		url(https://use.typekit.net/af/7bf543/000000000000000000012b3e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4)
		format("woff2"),
		url(https://use.typekit.net/af/e98bd4/000000000000000000012b3e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
		format("woff");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: adelle;
	src:
		url(https://use.typekit.net/af/9f15fd/000000000000000000012b3b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3)
		format("woff2"),
		url(https://use.typekit.net/af/0aab56/000000000000000000012b3b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
		format("woff");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: adelle;
	src:
		url(https://use.typekit.net/af/527324/000000000000000000012b2b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1)
		format("woff2"),
		url(https://use.typekit.net/af/85f81b/000000000000000000012b2b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3)
		format("woff");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: adelle;
	src:
		url(https://use.typekit.net/af/98de5c/000000000000000000012b2c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1)
		format("woff2"),
		url(https://use.typekit.net/af/35cd7e/000000000000000000012b2c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3)
		format("woff");
	font-weight: 100;
	font-style: italic;
}

/*@font-face {
	font-family: fira-sans;
	src:
		url(https://use.typekit.net/af/752e96/000000000000000000015891/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4)
		format("woff2"),
		url(https://use.typekit.net/af/193346/000000000000000000015891/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
		format("woff");
	font-weight: 400;
	font-style: normal;
}*/

/*@font-face {
	font-family: fira-sans;
	src:
		url(https://use.typekit.net/af/072be5/000000000000000000015892/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4)
		format("woff2"),
		url(https://use.typekit.net/af/7b374f/000000000000000000015892/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
		format("woff");
	font-weight: 400;
	font-style: italic;
}*/

/*@font-face {
	font-family: fira-sans;
	src:
		url(https://use.typekit.net/af/f4d040/00000000000000000001588f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3)
		format("woff2"),
		url(https://use.typekit.net/af/066512/00000000000000000001588f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
		format("woff");
	font-weight: 300;
	font-style: normal;
}*/

/*@font-face {
	font-family: fira-sans;
	src:
		url(https://use.typekit.net/af/cc761e/000000000000000000015895/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7)
		format("woff2"),
		url(https://use.typekit.net/af/e6a673/000000000000000000015895/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
		format("woff");
	font-weight: 700;
	font-style: normal;
}*/

/*@font-face {
	font-family: fira-sans;
	src:
		url(https://use.typekit.net/af/a37836/000000000000000000015896/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7)
		format("woff2"),
		url(https://use.typekit.net/af/79310d/000000000000000000015896/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
		format("woff");
	font-weight: 700;
	font-style: italic;
}*/

/*@font-face {
	font-family: fira-sans;
	src:
		url(https://use.typekit.net/af/c072e8/000000000000000000015879/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2)
		format("woff2"),
		url(https://use.typekit.net/af/09bef6/000000000000000000015879/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3)
		format("woff");
	font-weight: 200;
	font-style: normal;
}*/

/*@font-face {
	font-family: fira-sans;
	src:
		url(https://use.typekit.net/af/044d56/00000000000000000001587a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2)
		format("woff2"),
		url(https://use.typekit.net/af/5f3fc1/00000000000000000001587a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3)
		format("woff");
	font-weight: 200;
	font-style: italic;
}*/

/*@font-face {
	font-family: fira-sans;
	src:
		url(https://use.typekit.net/af/dac866/000000000000000000015880/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6)
		format("woff2"),
		url(https://use.typekit.net/af/c93c0e/000000000000000000015880/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3)
		format("woff");
	font-weight: 600;
	font-style: normal;
}*/



@media print{
    header, footer{ display: none !Important}
    #banner { display: none !Important}
    .breadcrumbs, .businessWidget{display: none !Important}
    .businessBox{width: 100% !Important}
    .impress{font-size: 1.2em !Important}
    .panel .panel-body{display: block !Important}
    .businessBox{color: #000!Important}
    .bg-dark-blue{background-color: #fff!Important;}
    .bg-dark-blue .color-white{color: #000!Important}
    .businessBox .bg-mid-grey-blue{ background-color: #fff}
    .breakPage{
      page-break-after:always!Important
    }
    @page {
        size: landscape;
    }
    /*body {
        writing-mode: tb-rl;
    }*/

}

.productDetails img{
  display: block;
  max-width:300px;
  max-height:auto;
  width: 300px;
  height: auto;
  float:left;
}

.productDetails a{
  text-decoration:none;
}
.productDetails td{
  margin-right: 50px;
  padding: 5px 0 10px 25px;
  border-bottom: 1px solid lightgray;
}
.productDetails th{
  margin-right: 50px;
  padding: 5px 0 10px 15px;
  border-bottom: 1px solid lightgray;
}
.productDetails table{
  margin-top:50px;
}
.productDetails table:last-of-type td{
  margin-right: 50px;
  padding: 5px 0 10px 25px;
  border-bottom: 0px solid lightgray;
}
.productDetails table:last-of-type th{
  margin-right: 50px;
  padding: 5px 0 10px 15px;
  border-bottom: 0px solid lightgray;
}
.productDetails table:last-of-type{
  clear: both;
}

.see-also ul{
  list-style-type:none !important;
  margin-left:0px !important;
}
.see-also a{
  background-image:url("data:image/svg+xml,%3Csvg width='9' height='10' viewBox='0 0 9 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23000' fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Cpath d='M1.25 5h7M8.75 5L4.992 1.463M8.75 5L4.992 8.537'/%3E%3C/g%3E%3C/svg%3E") !important;
  background-position:right center !important;
  background-repeat:no-repeat !important;
  padding-right:15px !important;
  text-decoration:none !important;
  color:#000 !important;
}

.button-disabled{
	opacity: 0.5;
}

.panel-image:before {    /* create a full-height inline block pseudo=element */
	content: ' ';
	display: inline-block;
	vertical-align: middle;  /* vertical alignment of the inline element */
	height: 100%;
	float: left;
}

.home-product-logo{
	display: inline-block;
	vertical-align: middle;  /* vertical alignment of the inline element */
	font: 16px/1 Arial sans-serif;        /* <-- reset the font property */
}

sup{
  vertical-align: super;
  font-size: smaller;
}
