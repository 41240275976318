/***************************** Mandatory Fields ******************************************/
label[for=capture_traditionalRegistration_personalData_title]:after
{
    color:#004267;
    content: "  *";
    font-weight:bold;
}
label[for=capture_traditionalRegistration_personalData_firstName]:after
{
    color:#004267;
    content: "  *";
    font-weight:bold;
}
label[for=capture_traditionalRegistration_professionalData_workCenterName]:after
{
    color:#004267;
    content: "  *";
    font-weight:bold;
}
label[for=capture_traditionalRegistration_professionalData_postalCode]:after
{
    color:#004267;
    content: "  *";
    font-weight:bold;
}
label[for=capture_traditionalRegistration_personalData_lastName]:after
{
    color:#004267;
    content: "  *";
    font-weight:bold;
}
label[for=capture_traditionalRegistration_registration_emailAddress]:after
{
    color:#004267;
    content: "  *";
    font-weight:bold;
}
label[for=capture_traditionalRegistration_registration_emailAddressConfirm]:after
{
    color:#004267;
    content: "  *";
    font-weight:bold;
}
label[for=capture_traditionalRegistration_registration_password]:after
{
    color:#004267;
    content: "  *";
    font-weight:bold;
}
label[for=capture_traditionalRegistration_registration_passwordConfirm]:after
{
    color:#004267;
    content: "  *";
    font-weight:bold;
}
label[for=capture_traditionalRegistration_professionalData_jobRole_primary]:after
{
    color:#004267;
    content: "  *";
    font-weight:bold;
}
label[for=capture_traditionalRegistration_professionalData_position]:after
{
    color:#004267;
    content: "  *";
    font-weight:bold;
}
label[for=capture_traditionalRegistration_professionalContactData_professionalNumber]:after
{
    color:#004267;
    content: "";
    font-weight:bold;
}
/****************************************************************************************/

/*********************** Override Janrain wodget css *************************************/
.reg-style-ch{
    padding: 0 !important;
    background-color: white !important;
    color: #000 !important;
    border: none !important;
    margin-top: 1.5em !important;
    text-decoration: underline !important;
    display: inline-block;
}
.capture_text_input{
    background-color: #fff;
    border: medium none;
    color: #004267!Important;
    font-family: "fira-sans", sans-serif;
    font-size: 1.0625em;
    font-weight: 200;
    margin: 0;
    padding: 14px 15px!important;
    width: 100%;
    border-color: #ccd9e1!Important;
    border-style: solid;
    border-width: 1px 2px 2px 1px!Important;
}
.capture_text,.capture_form_item label{
    padding-bottom: 4px;
    padding-top: 6px;
    text-transform: uppercase;
}
.capture_select {
     border: 1px solid #ccd9e1;
    display: inline-block;
    font-family: "fira-sans", sans-serif;
    font-size: 1.0625em;
    font-weight: 200;
    margin: 0;
    padding: 14px 15px!important;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    height: 53px!Important;
    width: 19px;
}
.capture_select ::after{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width=\'19\' height=\'12\' viewBox=\'842 587 19 12\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath stroke=\'%23FFF\' stroke-width=\'3\' fill=\'none\' d=\'M844 589l7.702 7 7.298-6.74\'/%3E%3C/svg%3E")!Important;
}
select::-ms-expand {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.janrain-capture-ui .capture_form_item {
    margin-bottom: 26px!Important;

}
.capture_btn{
    border: 1px solid #0072CE!Important;
    padding: 16px 20px!Important;
    font-family: "fira-sans", sans-serif!Important;
    font-size: .9375em!Important;
    border-radius: 0px!important;
   /* display: inline-block!Important;*/
    background-color: #0072CE;
    color: #fff!Important;
}
.capture_tip_error{
    width: 100%!Important;
    color: #EE255D;
}
.janrain-capture-ui .capture_tip_error{
    color: #EE255D!important;
    text-transform: uppercase;
}
.janrain-capture-ui .capture_processing{
    background-position: 1% center;
    background-image: none;
}

.capture_form_item{
    margin-top: 25px!important;
}
.capture_saveButton{
    margin-top: 23px!important;
}
.janrain-capture-ui .capture_tip{
    width: 100%!Important;
    color:black;
    text-transform: initial;
}

.janrain-capture-ui .capture_checkbox .capture_input_checkbox{
    position: absolute;
    top: 0;
    left: 0;
    width: 35px;
    height: 100%;
    overflow: hidden;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    opacity: 0;
}

.janrain-capture-ui .capture_checkbox label
{
    background-image: url("data:image/svg+xml,%3Csvg width=\'28\' height=\'28\' viewBox=\'709 489 28 28\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\'%3E%3Cdefs%3E%3Cpath id=\'a\' d=\'M709 489h27v26h-27z\'/%3E%3Cmask id=\'c\' x=\'0\' y=\'0\' width=\'27\' height=\'26\' fill=\'%23fff\'%3E%3Cuse xlink:href=\'%23a\'/%3E%3C/mask%3E%3Cpath id=\'b\' d=\'M711 491h26v26h-26z\'/%3E%3Cmask id=\'d\' x=\'0\' y=\'0\' width=\'26\' height=\'26\' fill=\'%23fff\'%3E%3Cuse xlink:href=\'%23b\'/%3E%3C/mask%3E%3C/defs%3E%3Cuse stroke=\'%23EBEBEC\' mask=\'url(%23c)\' stroke-width=\'2\' fill=\'none\' xlink:href=\'%23a\'/%3E%3Cuse stroke=\'%23BACBD4\' mask=\'url(%23d)\' stroke-width=\'2\' fill=\'none\' xlink:href=\'%23b\'/%3E%3Cpath fill=\'%23FFF\' fill-rule=\'evenodd\' d=\'M710 490h26v26h-26z\'/%3E%3C/svg%3E");
    background-position: 3px 4px;
    background-repeat: no-repeat;
    display: inline-block;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-top: 3px;
}
.checkSelected
{
    background-image: url("data:image/svg+xml,%3Csvg width=\'28\' height=\'28\' viewBox=\'709 489 28 28\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\'%3E%3Cdefs%3E%3Cpath id=\'a\' d=\'M709 489h27v26h-27z\'/%3E%3Cmask id=\'c\' x=\'0\' y=\'0\' width=\'27\' height=\'26\' fill=\'%23fff\'%3E%3Cuse xlink:href=\'%23a\'/%3E%3C/mask%3E%3Cpath id=\'b\' d=\'M711 491h26v26h-26z\'/%3E%3Cmask id=\'d\' x=\'0\' y=\'0\' width=\'26\' height=\'26\' fill=\'%23fff\'%3E%3Cuse xlink:href=\'%23b\'/%3E%3C/mask%3E%3C/defs%3E%3Cuse stroke=\'%23EBEBEC\' mask=\'url(%23c)\' stroke-width=\'2\' fill=\'none\' xlink:href=\'%23a\'/%3E%3Cuse stroke=\'%23BACBD4\' mask=\'url(%23d)\' stroke-width=\'2\' fill=\'none\' xlink:href=\'%23b\'/%3E%3Cpath fill=\'%23FFF\' fill-rule=\'evenodd\' d=\'M710 490h26v26h-26z\'/%3E%3Cpath fill=\'%23004A73\' fill-rule=\'evenodd\' d=\'M714 502.764l6.79 7.13 7.42-7.42 8.78-8.782-3.01-2.692-13.19 13.224-4.008-4.095\'/%3E%3C/svg%3E")!important;
}
#capture_signIn_userInformationForm_errorMessages .capture_form_error {
    color: rgb(245,112,3);
    font-size: 13px;
    background-position: 303px 0px!Important;
}

    /*********************** MODAL ****************************/


#janrainModal .janrain-capture-ui.capture-ui-content
{
    border-radius: 0px;
    khtml-border-radius: 5px;
    min-height: 350px;
    moz-border-radius: 5px;
    ms-border-radius: 5px;
    o-border-radius: 5px;
    padding: 20px 20px 70px 20px;
    webkit-border-radius: 5px;
    width: 600px;
    background-color: #fff;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
            box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
}

#janrainModal .capture_header {
    border-bottom: 2px solid #e5e5e5;
    box-sizing: border-box;
    margin-bottom: 15px;
    padding-bottom: 10px;
}
.janrain-capture-ui .capture_footer{
    margin-top: 0px!important;
}

#janrainModal .janrain_modal_closebutton {
    top: 10px;
    right: 10px;
    color: #004267;
    background-color: transparent;
    width: auto;
    height: auto;
    font-size: 21px;
  /*font-family: helvetica, sans-serif;*/
    font-weight: 600;
    text-decoration: none;
}
#capture_editProfile_form_item_inner_optIn_rebateAccess,#capture_traditionalRegistration_form_item_inner_optIn_rebateAccess{
    padding-left: 0px !important;
}
#capture_editProfile_form_item_inner_marketingConsent_channel_email,#capture_traditionalRegistration_form_item_inner_marketingConsent_channel_email{
    padding-left: 10px !important;
}
#capture_editProfile_form_item_optIn_rebateAccess,#capture_editProfile_form_item_inner_marketingConsent_channel_email,#capture_traditionalRegistration_form_item_optIn_rebateAccess,#capture_traditionalRegistration_form_item_marketingConsent_channel_email{
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}
.save-btn{
    padding-left: 10px;
}
#capture_traditionalRegistration_form_collection_marketingConsent_channel_email > label,#capture_editProfile_form_collection_marketingConsent_channel_email > label{
    padding-left: 0;
    text-transform: uppercase;
}
#capture_traditionalRegistration_form_collection_marketingConsent_channel_email > label:after, #capture_editProfile_form_collection_marketingConsent_channel_email > label:after{
    color: #004267;
    content: " *";
    font-weight: bold;
}
#capture_traditionalRegistration_form_item_marketingConsent_channel_email_yes > label{
    padding-left: 2em !important;
}
#capture_traditionalRegistration_form_item_marketingConsent_channel_email_no > label{
    padding-left: 2em !important;
}
#emailDisclaimer .markedNewLine>ul {
    margin-left: 20px;
}
#capture_traditionalRegistration_form_collection_marketingConsent_channel_email,#capture_editProfile_form_collection_marketingConsent_channel_email{
    padding-top: 2em;
    border-top: 2px solid #004267;
}
#capture_traditionalRegistration_form_item_optIn_rebateAccess, #capture_editProfile_form_item_inner_optIn_rebateAccess{
    padding-top: 2em;
    border-top: 2px solid #004267;
}